<template>
  <h2>WORK</h2>
  <div class="content">
    <div class="container">
      <GalleryItem
    v-for="item in portfolioItems"
    :key="item.id"
    :imgname="item.imgname"
    :pagename="item.pagename"
    :title="item.title"
    :date="item.date"
    ></GalleryItem>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import GalleryItem from '../components/GalleryItem.vue';
export default {
  name: "Home",
  components: {
    GalleryItem
  },
  data() {
    return {
      portfolioItems: [
        {id:1,imgname:'vlcsnap-2024-01-09-21h06m52s312.png',pagename:"work/genuary2024",title:"GENUARY SKETCHES",date:"2024"},
        {id:2,imgname:'a1.png',pagename:"work/arcadia",title:"ARCADIA",date:"2022"},
        {id:3,imgname:'surge.png',pagename:"work/surge",title:"SURGE",date:"2021"},
      ]
    }
  }
};
</script>